import React, { Component } from 'react'
import * as s from "../../styles/globalStyles";
import cubs from '../images/cubs.gif'
import lion from '../images/genesis-2.jpg'
import coin from '../images/genesis-3.jpg'
import ada from '../images/cardano-logo.png'
import eth from "../images/eth-logo.png"
import sol from '../images/solana-logo.png'
import egld from '../images/elrond-logo.png'
import background from "../images/genesis-bg.png";
import "../style/Genesis.css";


class Genesis extends Component {
    render() {
        return (
          <s.Screen style={{marginTop:100, backgroundImage: `url(${background})`}} >
            <div className='outer'>
            <div className='section-1'>
              <h1 className='title'>Curious Cubs Club</h1>
                <h2 className='sub-title'>OUR FRESHMAN COLLECTION</h2>
                  <img className='cub-gif' src={cubs} alt="cubs"/>
                  <p className='cub-text'>We are a NFT community bringing together artists and developers to collaborate on meaningful projects! Cubs were designed and developed in separate layers and then ran through the HashLips Art Engine to generate 777 unique and very special cubs.</p>
            </div>
            <div className='section-2'>
              <section className='past'>
                <p>Past...</p>
                <ul className='list'>
                  <li className='list-item'>777 Cubs sold on the Polygon Blockchain! Buy on secondary at <a className='link' href="https://opensea.io/collection/curiouscubsclub" target="blank">Opensea</a>.</li>
                  <li className='list-item'>$500 Donation Pledge to Wildlife Conservation Network <a className='link' href="https://www.lionrecoveryfund.org/" target="_blank">Lion Recovery Fund</a></li>
                  <li className='list-item'>Established a <a className='link' href="https://opensea.io/enlighteNFT" target="_blank">Community Wallet</a> to hodl, sell, and giveaway NFTs!</li>
                  <li className='list-item'>Helped Cub members with Smart Contracts and Deployment of their own projects!</li>
                  <li className='list-item'>Website, Discord, Instagram, Skurpy and Twitter Established! Find them all on <a className='link' href="https://linktr.ee/enlighte.NFT" target="_blank">Linktree</a>.</li>
                </ul>
              </section>
              <section className='present'>
                <p>Present</p>
                <ul className='list'>
                  <li className='list-item'>An amazing community of hodlers, weekly game nights hosted, plus various contests, and giveaways!</li>
                  <li className='list-item'>Our First NFT Collaboration with another project, <a className='link' href="https://linktr.ee/futureofcolor" target="_blank">Future of Color</a>, in the creation of a new derivative collection of 333 Color Cubs! Mint <a className='link' href="/color-cubs">Here</a> for 33 MATIC.</li>
                  <li className='list-item'>Collaboration with <a className='link' href="https://linktr.ee/IslesofMeta" target="_blank">Isles of Meta</a> and Galactic Island Holder with Exclusive Building/Design rights for Cub Holdrs.</li>
                  <li className='list-item'><a className='link' href="https://beyondearthonline.io/" target="_blank">Beyond Earth Online</a> Rare Plot reserved in Sector 16 for the Curious Cub Clubhouse!</li>
                </ul>
              </section>
              <section className='future'>
                <p>Future!</p>
                <ul className='list'>
                  <li className='list-item'>More Derivative Collections planned, with Airdrops and Discounts for Hodlers.</li>
                  <li className='list-item'>$1000+ in total donations to the Lion Recovery Fund.</li>
                  <li className='list-item'>Community based funds managed through DAO for spending, withdrawals, and transaction requests.</li>
                  <li className='list-item'>Additional Collaborations to expand our Network and partner with new Artists / Developers!</li>
                  <li className='list-item'>On-boarding resources for new Crypto Pioneers such as Info for Newbies section, How-to Videos, and Medium posts.</li>
                </ul>
              </section>
            </div>
            <div className='section-3'>
              <section className='donation-section'>
                <img className='donation-img' src={lion} alt='lion'/>
                <div className='overlay'>
                  <h2 className='donation-title'>Charitable Donations</h2>
                  <p className='donation-body'>45% of all profits will be donated to the Wildlife Conservation Network to support their Lion Recovery Fund!</p>
                  <a className='donation-button' href='https://www.lionrecoveryfund.org/' target="_blank">Learn More</a>
                </div>             
              </section>
              <section className='multi-section'>
                <img className='multi-img' src={coin} alt='coin' />
                <div className='overlay'>
                  <h2 className='multi-title'>Multi-Chain Integration</h2>
                  <p className='multi-body'>We would like to maximize our donations by taking our cubs multi-chain! This will help build our community and spread awareness to pioneers throughout various blockchains! Hodler input will help decide which chains to deploy our cubs on.</p>
                </div>
              </section>
            </div>
            <div className='section-4'>
              <section className='chain-section'>
                <img className='crypto-img' src={sol} alt='sol'/>
                <h3 className='chain-name'>Solana</h3>
                <p className='chain-body'>Solana is becoming a powerful force in the NFT ecosystem. With integration into OpenSea and a rise in popularity, it would be fitting to deploy our cubs on this chain next! Additionally, we have an island on Isles of Meta, a new and upcoming metaverse on Solana.</p>
              </section>
              <section className='chain-section'>
                <img className='crypto-img' src={ada} alt='ada'/>
                <h3 className='chain-name'>Cardano</h3>
                <p className='chain-body'>If you haven't heard of NFT projects on Cardano, also known as CNFTs, then you need to explore them. A few staples include Yummi Universe, Mocossi Planet, Clays, and more! Cardano has a great community and we would love to be a part of it!</p>
              </section>
              <section className='chain-section'>
              <img className='crypto-img' src={egld} alt='egld'/>
                <h3 className='chain-name'>Elrond</h3>
                <p className='chain-body'>There are many other blockchains to choose from and we have time to integrate! We may even deploy on experimental blockchains and be among the first in a new ecosystem! Join our discord for the latest updates and voting rounds.</p>
              </section>
              <section className='chain-section'>
                <img className='crypto-img' src={eth} alt='eth'/>
                <h3 className='chain-name'>Ethereum</h3>
                <p className='chain-body'>There are many other blockchains to choose from and we have time to integrate! We may even deploy on experimental blockchains and be among the first in a new ecosystem! Join our discord for the latest updates and voting rounds.</p>
              </section>
            </div>
            </div>
          </s.Screen>

)
}
}

export default Genesis