// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import Web3Modal from "web3modal";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from "@walletconnect/web3-provider";

// log
import { fetchData } from "../data/dataActions";

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const providerOptions = {
        coinbasewallet: {
        package: CoinbaseWalletSDK, // Required
        options: {
          appName: "enlighteNFT DAPP", // Required
          infuraId: CONFIG.INFURA_ID, // Required
          chainId: 137, // Optional. It defaults to 1 if not provided
          darkMode: false // Optional. Use dark theme, defaults to false
        },
        },
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            rpc: {
              1: "https://mainnet.infura.io/v3/",
              4: "https://rinkeby.infura.io/v3/",
              137: "https://polygon-rpc.com/"
            }
          }
        }
    };
    
    const web3Modal = new Web3Modal({
      cacheProvider: false,
      disableInjectedProvider: false,
      providerOptions,
      theme: {
        background: "rgb(0,204,204)",
        main: "rgb(102, 0, 102)",
        secondary: "rgb(153, 0, 153)",
        border: "rgba(195, 195, 195, 10)",
        hover: "rgb(255, 255, 0)"
      }
    });


    let provider = await web3Modal.connect()
      .catch(err => {
        console.log(err)
        dispatch(connectFailed("Failed to Connect to Wallet. Try again!"))
      })

      if (provider.overrideIsMetaMask === undefined) {
        Web3EthContract.setProvider(provider);
      } else {
        provider = window.ethereum.providers.find((x) => x.isMetaMask)
        Web3EthContract.setProvider(provider);
      }

      let web3 = new Web3(provider);
      const acct = await web3.eth.getAccounts();
      await web3.eth.personal.sign("Hello,\n\nThank you for choosing to mint a Curious Pixel Cub NFT! You will receive a discount for holding our Genesis collection. If you have any issues, please reach out to me on Twitter or Discord before minting.\n\nBest wishes,\n\nenlighteNFT", `${acct[0]}`, "null")
        .catch(err => {
          console.log(err)
          window.alert("Signature rejected, window will be automatically refreshed.\n\nPlease sign to continue. <3");
          window.location.reload();
        })

      try {
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const networkId = await provider.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          provider.on("accountsChanged", (accounts) => {
            if (!accounts || accounts.length === 0) {
                window.alert("Wallet(s) disconnected, window will be automatically refreshed.\n\nPlease connect wallet and try again. 🦁");
                window.location.reload();
            } else {
            dispatch(updateAccount(accounts[0]));
            }
          });
          provider.on("chainChanged", () => {
            window.alert("Change in Network detected, window will be automatically refreshed.\n\nPlease connect to Polygon Network and try again. 🦁");
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Failed to Connect to Wallet. Try again!"));
      }
    } 
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};