import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './data/logo.svg'
import './style/NavBar.css';

function NavBar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar' style={{position: "fixed", width: "100%" }} >
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={logo} width="80" height="80" alt="enlighten.global"/>   
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/curious-cubs-club'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Curious Cubs Club
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/color-cubs'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              FoC x CCC
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/pixel-cubs'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Pixel Cubs
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/mutant-cubs'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              AI Mutants x CCC
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/blog'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Blog
            </Link>
          </li>
        </ul>
      </nav>

    </>
  );
}

export default NavBar;