import React, { Component } from 'react'
import * as s from "../../styles/globalStyles";
import "../style/Blog.css";
import BlogPost from '../data/blogpost'
import background from "../images/blog-bg.png";
import posts from '../data/postsData'

class Blog extends Component {
  render() {
    return (
      <s.Screen style={{marginTop:100, backgroundImage: `url(${background})`}} >
        <div className="App">
          <main className='Posts'>
          <h1>My Blog Posts</h1>
            {posts.map((post, index) => (
              <BlogPost key={index} {...post} />
            ))}
          </main>
        </div>
      </s.Screen>
    );
  }
}

export default Blog