import React, { useEffect, useState} from 'react'
import * as s from "../../styles/globalStyles";
import "../style/Mutants.css";
import styled from "styled-components";
import { mutants } from '../data/mutants';
import Mutant from "./Mutant";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/AIblockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import image from "../images/ai-mut.gif";
import InfiniteScroll from 'react-infinite-scroller';

export const StyledConnectButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: var(--button);
  padding: 15px;
  font-family: 'overdrive_sunsetregular';
  color: black;
  width: 200px;
  height: 65px;
  cursor: pointer;
  letter-spacing: 1.7px;
  @media (max-width: 376px) {
    width: 180px;
    height: auto;
  }
  @media (max-width: 300px) {
    width: 120px;
    height: auto;
  }
  -webkit-animation: text-focus-in 2.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: text-focus-in 2.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

  :hover {
    background-color: red;
    color: white;
    transition: 0.7s;
  }
`;

export const StyledImg = styled.img`
  background-color: black;
  border-radius: 1000px;
  padding: 7px;
  width: 100%;
  max-width: 360px;
  -webkit-animation: text-focus-in .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: text-focus-in .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  @media (max-width: 500px) {
    max-width: 250px
    transition: width 0.5s;
    transition: height 0.5s;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  font-size: 14px;
  font-weight: bolder;
  letter-spacing: 1.7px;
  text-decoration: none;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
   :hover {
    color: teal;
    transition: 0.7s;
  }
`;


const Mutants = () => {
const dispatch = useDispatch();
const blockchain = useSelector((state) => state.blockchain);
const [state, setState] = useState({
  items: mutants.slice(0, 20),
  hasMore: true
})
let itemsLoaded;
let addMore;
let test;
let test2;
let test3;
const data = useSelector((state) => state.data);
const [CONFIG, SET_CONFIG] = useState({
  CONTRACT_ADDRESS: "",
  SCAN_LINK: "",
  NETWORK: {
    NAME: "",
    SYMBOL: "",
    ID: 0,
  },
  NFT_NAME: "",
  SYMBOL: "",
  MAX_SUPPLY: 1,
  WEI_COST: 0,
  WL_WEI_COST: 0,
  DISPLAY_COST: 0,
  WHITELIST_COST: 0,
  HOLDER_MINT: false,
  GAS_LIMIT: 0,
  PRIORITY_FEE: 0,
  ADD_GWEI: 0,
  CHAIN_EXPLORER: "",
  MARKETPLACE: "",
  MARKETPLACE_LINK: "",
  INFURA_LINK: "",
  SHOW_BACKGROUND: false
});

const getData = () => {
  if (blockchain.account !== "" && blockchain.smartContract !== null) {
    dispatch(fetchData(blockchain.account));
  }
};

const getConfig = async () => {
  const configResponse = await fetch("/config/config.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const config = await configResponse.json();
  SET_CONFIG(config);
};

const loadItems = () => {
  itemsLoaded = state.items.length
  addMore = +itemsLoaded + +20
  test = mutants.slice(itemsLoaded, addMore)
  test2 = state.items
  test3 = test2.concat(test)

  setState({
    items: test3,
    hasMore: true
  });

};

const loadFunc = () => {
  if (state.items.length >= 760) {
    setState({
      items: mutants,
      hasMore: false
    });
    return;
  } else {
    setTimeout(loadItems, 500)
  }
};

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

return (
  <div className='mutant'>
    <s.Container  
      style={{ 
        paddingRight: 50,
        paddingLeft: 50,
        paddingTop: 25,
      }}>
      <StyledImg alt={"image"} src={image} />
    </s.Container>
    <s.SpacerSmall />
    <s.Container
      style={{ 
        paddingTop: 0,
        }}
    >
      <s.TextTitle
        style={{
          textAlign: "center",
          fontFamily: 'overdrive_sunsetregular',
          color: "var(--accent-text)",
          paddingTop: 0,
          backgroundColor: "coral",
          padding: 10,
          borderRadius: 10,
          border: "5px solid black"
        }}
      >
        Ai Mutants x Curious Cubs Club
      </s.TextTitle>
      <s.SpacerSmall />
        <s.TextLink
          style={{
            textAlign: "center",
            fontFamily: 'overdrive_sunsetregular',
            color: "var(--accent-text)",
            backgroundColor: "gold",
            padding: 5,
            lineHeight: 1,
            borderRadius: 20,
            border: "3px solid black"
          }}
        >
        <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
            View Contract on {CONFIG.CHAIN_EXPLORER}
        </StyledLink>
        </s.TextLink>
      <s.SpacerSmall />
      {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
        <>
          <s.TextSubTitle
            style={{
              textAlign: "center",
              fontFamily: 'overdrive_sunsetregular',
              color: "var(--accent-text)",
            }}
          >
            The sale has ended but you can buy {CONFIG.NFT_NAME} on
          </s.TextSubTitle>
          <s.SpacerSmall />
          <div style={{
              textAlign: "center",
              fontSize: 24,
              fontFamily: 'overdrive_sunsetregular',
              color: "var(--accent-text)",
              backgroundColor: "coral",
              padding: 10,
              borderRadius: 10,
              border: "5px solid black"
            }}
            target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
            {CONFIG.MARKETPLACE}
          </div>
        </>
      ) : (
        <>
          {blockchain.account === "" ||
          blockchain.smartContract === null ? (
            <s.Container ai={"center"} jc={"center"}>
              <StyledConnectButton
                style={{ 
                  fontSize: 24,
                  borderRadius: 20,
                  border: "4px solid black"
                }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                CONNECT
              </StyledConnectButton>
              
              {blockchain.errorMsg !== "" ? (
                <>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      fontSize: 18,
                      letterSpacing: 1.1,
                      fontWeight: "bolder",
                      fontFamily: 'overdrive_sunsetregular',
                      backgroundColor: "white",
                      padding: 15,
                      border: "5px solid black",
                      borderRadius: 50,
                      color: "red",
                      marginBottom: 15
                    }}
                  >
                    Error: {blockchain.errorMsg}
                  </s.TextDescription>
                </>
              ) : null}
            </s.Container>
            
          ) : (
            <>
          <InfiniteScroll
              pageStart={0}
              loadMore={loadFunc}
              hasMore={state.hasMore}
              loader={<div className="loader" key={0}>Loading ...</div>}
          >
            <div className='image'>
              {state.items.map((item) => (
                  <Mutant item={item} key={item.id} />
              ))}
              </div>
          </InfiniteScroll>
          </>
          )}
        </>
      )}
      </s.Container>
  </div>
  )
}

export default Mutants