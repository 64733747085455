import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import * as s from "../styles/globalStyles";
import Footer from '../components/Footer'
import NavBar from '../components/NavBar';
import { Home, Color, Genesis, Pengies, Pixel, Mutants, NameChange, Blog } from '../components/pages'

import 'bootstrap/dist/css/bootstrap.min.css'


function App() {
    return (
      <s.Screen>
        <Router>
            <NavBar />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path='/curious-cubs-club' element={<Genesis />} />
                <Route path='/code-name-p3' element={<Pengies />} />
                <Route path='/pixel-cubs' element={<Pixel />} />
                <Route path='/color-cubs' element={<Color />} />
                <Route path='/mutant-cubs' element={<Mutants />} />
                <Route path='/name-change' element={<NameChange />} />
                <Route path='/blog' element={<Blog />} />
            </Routes>
            <Footer />
        </Router>
        </s.Screen>
    )
}

export default App