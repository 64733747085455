import React, { Component, useCallback } from 'react'
import * as s from "../../styles/globalStyles";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import "../style/Home.css";

const Home = () => {

  const particlesInit = useCallback(async (engine) => {
  await loadFull(engine);
  }, []);
        return (
          <s.Screen className='home-screen'>
            <div className='home-outer'>
            <h1 className='home-title'>enlighteNFT</h1>
            <div className='home-section-1'>
              <ul className='enft-list'>
                  <li className='enft-item'>Art</li>
                  <li className='enft-item'>Utility</li>
                  <li className='enft-item'>Longevity</li>
                  <li className='enft-item'>Community</li>
                </ul>
                <p className='enft-text'>enlighteNFT aims to collaborate with artists, developers, and the community to bring meaningful projects into the Metaverse. Our first project, Curious Cubs Club, will be our freshman collection in the world of NFT art and utility. We are striving for longevity in the NFT space.</p>
            </div>
            <div className='particles'>
            <Particles
              id="tsparticles"
              init={particlesInit}
              options={{
                
                fpsLimit: 120,
                interactivity: {
                  events: {
                    onClick: {
                      enable: false,
                      mode: "push",
                    },
                    onHover: {
                      enable: true,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 100,
                      duration: 0.5,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#ffffff",
                  },
                  links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: false,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    directions: "none",
                    enable: true,
                    outModes: {
                      default: "bounce",
                    },
                    random: false,
                    speed: 7,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 700,
                    },
                    value: 160,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "star",
                  },
                  size: {
                    value: { min: .1, max: 1 },
                  },
                },
                detectRetina: true,
              }} />
            </div>
            </div>
          </s.Screen>

)
}

export default Home