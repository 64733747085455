import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/FxCblockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import '../../app/index.css';
import background from "../images/color-bg.png";

export const StyledConnectButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: var(--button);
  padding: 15px;
  font-family: 'lt_colored_pencilregular';
  color: var(--secondary-text);
  width: 200px;
  height: 65px;
  cursor: pointer;
  letter-spacing: 1.7px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  @media (max-width: 376px) {
    width: 180px;
    height: auto;
  }
  @media (max-width: 300px) {
    width: 120px;
    height: auto;
  }
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  
 
`;

export const StyledMintButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: var(--button);
  padding: 15px;
  font-family: 'lt_colored_pencilregular';
  color: var(--secondary-text);
  width: 150px;
  cursor: pointer;
  letter-spacing: 1.7px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  @media (max-width: 376px) {
    width: 180px;
    height: auto;
  }
  @media (max-width: 300px) {
    width: 120px;
    height: auto;
  }
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
`;

export const StyledWLButton = styled.button`
  padding: 10px;
  border-radius: 40px;
  border: none;
  background-color: var(--button);
  padding: 7px;
  font-family: 'lt_colored_pencilregular';
  color: var(--secondary-text);
  width: 150px;
  font-weight: bolder;
  letter-spacing: 1.7px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  -webkit-animation: jello-horizontal 0.9s both;
	        animation: jello-horizontal 0.9s both;
         
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100px;
  border: none;
  background-color: var(--button);
  padding: 10px;
  font-family: 'lt_colored_pencilregular';
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
   :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  
`;


export const ResponsiveWrapper = styled.div`
display: column;
padding: 50px;
position: relative;
margin-top: 150px;
margin-bottom: 50px;
margin-left: auto;
margin-right: auto;
width: 80%;
justify-content: center;
border-radius: 40px;
align-items: center;
max-width: 700px;
@media (max-width: 600px) {
  width: 100%;
  padding: 5px;
}
  background:-moz-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:-webkit-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:-o-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:-ms-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);

  @media (max-width: 700px) {
    max-width: 500px
    transition: width 0.5s;
    transition: height 0.5s;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 1000px;
  padding: 7px;
  width: 100%;
  max-width: 420px;
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-animation: text-focus-in .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: text-focus-in .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  @media (max-width: 500px) {
    max-width: 250px
    transition: width 0.5s;
    transition: height 0.5s;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 1.7px;
  text-decoration: none;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
   :hover {
    color: var(--hover);
    transition: 0.7s;
  }
`;

export const Link = styled.a`
  justify-content: center;
  drop-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: var(--hover);
    transition: 0.7s;
  }
`;


export default function Color() {
  const Web3 = require('web3');
  const options = {
    reconnect: {
        auto: true,
        delay: 5000, // ms
        maxAttempts: 5,
        onTimeout: false
    }
  }; 
  const abi = require('../data/CCC_abi.json');
  const CCC_address = '0xfA4bB27710156A8A685f85eD76e747C3B75B0791';
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [displayCost, setDisplayCost] = useState(`1 FXC costs 22 MATIC`)
  const [feedback, setFeedback] = useState(`Click "Mint" to Continue`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    WL_WEI_COST: 0,
    DISPLAY_COST: 0,
    WHITELIST_COST: 0,
    GAS_LIMIT: 0,
    ADD_GWEI: 0,
    CHAIN_EXPLORER: "",
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    TWITTER_LINK: "",
    DISCORD_LINK: "",
    INFURA_LINK: "",
    SHOW_BACKGROUND: false,
    WHITELIST: [],
  });
  const web3 = new Web3(CONFIG.INFURA_LINK, options);
  var genesisContract = new web3.eth.Contract(abi, CCC_address);
  const addresses = CONFIG.WHITELIST;
  const claimNFTs = async () => {
    let cost;    
    let balance = await web3.eth.getBalance(blockchain.account);
    let total = String(balance);
    let gasLimit = CONFIG.GAS_LIMIT;
    let feeGWEI = await web3.eth.getGasPrice();
    let maxGWEI = String(+feeGWEI + +CONFIG.ADD_GWEI);
    let address = blockchain.account
    let checkWL = false;
    let leaf;

      for (let i=0; i<addresses.length;i++) {
          leaf = addresses[i].toLowerCase()
          if ( leaf === address.toLowerCase()) {
            checkWL = true
              console.log("Success, you are on the whitelist!");
          }
      }

      if (checkWL === true) {
        cost = CONFIG.WL_WEI_COST;
        console.log("DISCOUNT APPLIED");
      } else {
        cost = CONFIG.WEI_COST;
        console.log("No Discounts applied.");
      }

    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

    console.log("Cost: ", totalCostWei);
    console.log("Your Balance: ", total)
    console.log("Max Gwei: ", maxGWEI);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    
    if (total < cost * mintAmount) {
      console.log("Failed, insufficient funds.");
      setFeedback(`ERROR: Insufficent Funds`);
      setClaimingNft(false);
    } else { 
      blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: totalGasLimit,
        maxPriorityFeePerGas: maxGWEI,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(err.message);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have minted a ${CONFIG.NFT_NAME}! Visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    } 
  };

  const updateCost = async () => { 
    let address = await blockchain.account;
    let holder = await genesisContract.methods.balanceOf(address).call().catch(err => {console.log(err)})
    let leaf;
    let whitelisted = false

    console.log("Genesis Cubs Owned:", holder)

      for (let i=0; i<addresses.length;i++) {
          leaf = addresses[i].toLowerCase()
          if ( leaf === address.toLowerCase() ) {
              window.alert("Success, you are on the whitelist.\n\nDiscount will be applied!");
              setDisplayCost(
                `1 FXC costs 11 MATIC`
              );
              whitelisted = true
          }
      }

    if (whitelisted === false && holder > 0) {
      setDisplayCost(`1 FXC costs 33 MATIC`)
      window.alert("You are a Genesis holder but have not been whitelisted.\n\nPlease contact enlighteNFT to be added for a discount on mint.")
    } else if (whitelisted === false) {
      setDisplayCost(`1 FXC costs 22 MATIC`)
      window.alert("Sorry, you are not on the whitelist.")
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/FxCconfig.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
        <s.Screen style={{ backgroundImage: `url(${background})`, paddingLeft: 25, paddingRight: 25}}>
        <ResponsiveWrapper flex={1}>
          <s.Container  
          style={{ 
          paddingRight: 50,
          paddingLeft: 50,
          paddingTop: 25,
          }}>
            <StyledImg alt={"image"} src={"/config/images/image.gif"} />
          </s.Container>
          <s.SpacerMedium />
          <s.ColorSpacerLine />
          <s.Container
            style={{ 
              paddingTop: 0,
              }}
          >
             <s.TextTitle
              style={{
                textAlign: "center",
                fontFamily: 'lt_colored_pencilregular',
                color: "var(--accent-text)",
                paddingTop: 0,
              }}
            >
              Future of Color x Curious Cubs Club
            </s.TextTitle>
            <s.ColorSpacerLine />
            <s.SpacerXSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextSubTitle
                  style={{
                    textAlign: "center",
                    fontFamily: 'lt_colored_pencilregular',
                    color: "var(--accent-text)",
                  }}
                >
                  The sale has ended but you can buy {CONFIG.NFT_NAME} on
                </s.TextSubTitle>
                <s.SpacerSmall />
                <StyledMintButton style={{
                    textAlign: "center",
                    fontSize: 24,
                    fontFamily: 'lt_colored_pencilregular',
                    color: "var(--accent-text)",
                  }}
                  target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledMintButton>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextPreTitle 
                      style={{
                        textAlign: "center",
                        fontFamily: 'lt_colored_pencilregular',
                        color: "var(--accent-text)",
                        paddingTop: 15,
                        fontSize: 28
                      }}
                    >
                      Click "Connect" to Start
                    </s.TextPreTitle>
                    <s.SpacerSmall />
                    <StyledConnectButton
                      style={{ 
                        fontSize: 24,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledConnectButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerLarge />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bolder",
                            fontFamily: 'lt_colored_pencilregular',
                            color: "red",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        fontFamily: 'lt_colored_pencilregular',
                        fontSize: 30,
                        color: "var(--accent-text)",
                    }}
                    >
                    {displayCost}
                    </s.TextDescription>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        fontSize: 30,
                        fontFamily: 'lt_colored_pencilregular',
                        color: "var(--accent-text)",
                      }}
                    >
                      {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                    </s.TextDescription>
                    <s.SpacerXSmall />
                    <s.Container>
                      <StyledWLButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          updateCost();
                          getData();
                        }}
                      >
                        CHECK WHITELIST
                      </StyledWLButton>
                    </s.Container>
                    <s.SpacerSmall />
                        <s.TextSubTitle
                          style={{
                            textAlign: "center",
                            fontStyle: "italic",
                            letterSpacing: 1,
                            fontFamily: 'lt_colored_pencilregular',
                            color: "var(--accent-text)",
                          }}
                        >
                          {feedback}
                    </s.TextSubTitle>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ 
                          lineHeight: 0.4,
                          fontSize: 28,
                         }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          fontFamily: 'lt_colored_pencilregular',
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        style={{ 
                          lineHeight: 0.4,
                          fontSize: 28,
                        }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerXSmall />
                    <s.Container>
                      <StyledMintButton
                        style={{ 
                          fontSize: 24,
                         }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "MINT"}
                      </StyledMintButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
             <s.TextLink
              style={{
                textAlign: "center",
                fontFamily: 'lt_colored_pencilregular',
                color: "var(--accent-text)",
                paddingTop: 10,
                paddingBottom: 35
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                View Contract on {CONFIG.CHAIN_EXPLORER}
              </StyledLink>
            </s.TextLink>
          </s.Container>
        </ResponsiveWrapper>
        </s.Screen>
  );
}

