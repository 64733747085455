import styled from "styled-components";

const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;

const Container = styled.div`
  flex: 1;
  margin: 25px;
  max-width: 350px;
  height: 400px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #009eac;
  border-radius: 40px;
  position: relative;
  }
`;

const Circle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
`;

const Image = styled.img`
  height: 75%;
  z-index: 2;
  padding: 15px;
`;

export const StyledMintButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: gold;
  padding: 10px;
  font-size: 20px;
  font-family: 'overdrive_sunsetregular';
  color: black;
  width: 150px;
  margin: 5px;
  cursor: pointer;
  letter-spacing: 1.7px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  @media (max-width: 376px) {
    width: 180px;
    height: auto;
  }
  @media (max-width: 300px) {
    width: 120px;
    height: auto;
  }
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
`;

const Mutant = ({ item }) => {
  return (
    <Container>
      <Image src={"https://i.imgur.com/Fny81Xq.jpg"} />
      <Text>Mutant Cub #{item.id}</Text>
      <StyledMintButton
        //disabled={claimingNft ? 1 : 0}
        onClick={(e) => {
         // e.preventDefault();
         // claimNFTs();
         // getData();
         window.alert(`Button for Cubs #${item.id} was pressed`)
        }}
      >
       Mint
       </StyledMintButton>
    </Container>
  );
};

export default Mutant;