import React from 'react';
import DOMPurify from 'dompurify';
import "../style/BlogPost.css";

const BlogPost = ({ title, date, content }) => {
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <div className="blog-post">
      <h2>{title}</h2>
      <p className="date">Posted on <span className="post-date">{date}</span></p>
      <div className="posts" dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>
    </div>
  );
};

export default BlogPost;