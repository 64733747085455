import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/NCblockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import '../../app/index.css';
import background from "../images/color-bg.png";

const ResponsiveWrapper = styled.div`
  display: grid;
  grid-template-rows: [row1-start] 1fr [row1-end] auto [last-line];
  grid-template-columns: [first]  1fr [line2] 1fr [end];
  gap: 10px;
  align-items: center;
  justify-items: stretch;
  padding: 50px;
  position: relative;
  margin-top: 150px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 80px;
  max-width: 1200px;
  font-family: 'pixellarimedium';

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-evenly;
    flex-flow: column wrap;
    max-width: 700px;
    transition: width 0.5s;
    transition: height 0.5s;
    gap: 10px;
  }
  @media (max-width: 700px) {
    max-width: 500px;
    padding: 70px;
  }
  @media (max-width: 500px) {
    max-width: 100%;
    padding: 25px;
  }
  @media (max-width: 280px) {
    border-radius: 35px;
    padding: 5px;
  }
    background:-moz-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%); 
    background:-webkit-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
    background:-o-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00115D', endColorstr='#00FFEE', GradientType=1 );
    background:-ms-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
    background:linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);width: 100%;
`;

const StyledConnectButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: var(--button);
  padding: 15px;
  font-family: 'pixellarimedium';
  color: var(--secondary-text);
  width: 150px;
  cursor: pointer;
  letter-spacing: 1.7px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  @media only screen and (max-width: 280px) {
    letter-spacing: 1px;
    width: 120px;
    }
`;

const StyledMintButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: var(--button);
  padding: 15px;
  margin-bottom: 25px;
  font-family: 'pixellarimedium';
  color: var(--secondary-text);
  width: 150px;
  cursor: pointer;
  letter-spacing: 1.7px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  @media only screen and (max-width: 280px) {
    width: 100px;
    }
`;

const StyledImg = styled.div`
  margin-bottom: 445px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  max-width: 420px;
  padding: 15px;
 @media (max-width: 512px) {
    max-width: 256px;
    margin-top: 15px;
    margin-bottom: 276px;
  }
  @media (max-width: 360px) {
    margin-top: 5px;
    margin-bottom: 215px;
  }
  @media (max-width: 280px) {
    margin-top: 10px;
    margin-bottom: 160px;
  }
`;

const NameChange = () => {
    const Web3 = require('web3');
    const options = {
        reconnect: {
            auto: true,
            delay: 5000,
            maxAttempts: 5,
            onTimeout: false
        }
    };
    const abi = require('../data/CPC_abi.json');
    const address = '0x90Cd0448C0207D94694244C0e1D813C287644d2e';
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [name, setName] = useState("")
    const [feedback, setFeedback] = useState(``);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
        NAME: "",
        SYMBOL: "",
        ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        WL_WEI_COST: 0,
        DISPLAY_COST: 0,
        WHITELIST_COST: 0,
        GAS_LIMIT: 0,
        ADD_GWEI: 0,
        CHAIN_EXPLORER: "",
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        TWITTER_LINK: "",
        DISCORD_LINK: "",
        INFURA_LINK: "",
        SHOW_BACKGROUND: false,
        WHITELIST: [],
    });

    const web3 = new Web3(CONFIG.INFURA_LINK, options);
    var contract = new web3.eth.Contract(abi, address);
    const [lonk, setLonk] = useState(`/config/images/preview.png`);

    const nameChange = async () => {
        let address = await blockchain.account;
        let holder = await contract.methods.balanceOf(address).call().catch(err =>  {console.log(err)});

        if (holder <= 0) {
            window.alert("You do not own a Pixel Cub, this service is for holders only!")
            return
        } else if (name === "") {
            window.alert("Please name your cub to Mint!")
            return
        } else if (name.length > 20) {
            window.alert("Cubs have a 20-character limit. Please rename your cub to Mint!")
            return
        }
    
    }

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
          dispatch(fetchData(blockchain.account));
        }
      };
    
      const getConfig = async () => {
        const configResponse = await fetch("/config/FxCconfig.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
      };
    
      useEffect(() => {
        getConfig();
      }, []);
    
      useEffect(() => {
        getData();
      }, [blockchain.account]);

      return (
        <s.Screen flex={1} ai={"center"} jc={"center"} style={{ 
            
            paddingLeft: 25,
            paddingRight: 25
        }}
        >
            <ResponsiveWrapper>
            <s.Container style={{ 
                    maxWidth: 540,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 0,
                    marginBottom: 5
                
            }}>
                <s.SpacerLine style={{
                background: "#FFFFFF",
                borderRadius: 0, 
                padding: 2,
                marginBottom: 5
                }}/>   
                <s.SpacerSmall />
                <p style={{ 
                    background: "linear-gradient(to top, #00EEFF 0%, #FFFFFF 50%)", 
                    WebkitBackgroundClip: "text", 
                    WebkitTextFillColor: "transparent", 
                    fontFamily: 'pixellarimedium',
                    fontSize: 36,
                    fontWeight: "bolder"
                }}>
                Pixel Name Changer
                </p>
                <s.SpacerSmall />
                <s.SpacerLine style={{
                background: "#FFFFFF",
                borderRadius: 0, 
                padding: 2
                }}/>
            </s.Container>

            <StyledImg jc={"center"} style={{ left: -420 }}>
              <img className="layers" alt={"image"} src={lonk} />
            </StyledImg>
          <s.Container style={{ fontFamily: 'pixellarimedium'}}>
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container>
                    <s.SpacerSmall />
                    <StyledConnectButton
                      style={{ 
                        fontSize: 20,
                        fontFamily: 'pixellarimedium'
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledConnectButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerLarge />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bolder",
                            fontFamily: 'pixellarimedium',
                            color: "red",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>

                    <div className="Form">

                    <form onSubmit={nameChange} style={{color: "white"}}>
                     
                    <s.SpacerXSmall />
                       Name Your Cub: 
                    <s.SpacerXSmall />
                    <input
                        type="text"
                        value={name}
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <s.SpacerSmall />
                    <s.TextSubTitle style={{ fontFamily: 'pixellarimedium'}}>
                      {feedback}
                    </s.TextSubTitle>
                    <s.SpacerSmall />
                    <s.Container>
                      <StyledMintButton  
                        type="submit"
                        style={{ fontSize: 24 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={async (e) => {
                          e.preventDefault();
                          getData();
                          nameChange()
                          .catch(() => {
                            console.log("Error, please try again!")
                            window.alert("Server may be disconnected, please report to team!")
                            setClaimingNft(false);     
                          });
                        }}
                      >
                        {claimingNft ? "BUSY" : "SUBMIT"}
                      </StyledMintButton>
                    </s.Container>
                    </form>
                    </div>
                  </>
                )}
              </>
          </s.Container>
          </ResponsiveWrapper>
          </s.Screen>
  );               
}

export default NameChange