import styled from "styled-components";

export const Screen = styled.div`
  background-color: var(--primary);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerLine = styled.div`
  display: inline-block;
  border-radius: 420px;
  width: 100%;
  @media (min-width: 200px) {
    max-width: 700px;
  }
  padding: 1px;
  background: #000000;
`;

// Used for providing space between components
export const ColorSpacerLine = styled.div`
  display: inline-block;
  border-radius: 420px;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: 200px) {
    max-width: 700px;
  }
  padding: 1px;
  background:-moz-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:-webkit-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:-o-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:-ms-radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
  background:radial-gradient(circle at 49% 0%, rgba(255, 255, 0, 0.75) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 4px;
  width: 4px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "space-evenly")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 36px;
  font-weight: bolder;
  font-family: 'lt_colored_pencilregular';
  color: var(--primary-text);
  text-align: center;
  line-height: 1.4;
  @media (max-width: 376px) {
    font-size: 30px;
  }
  @media (max-width: 300px) {
    font-size: 24px;
  }
  -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  
`;

export const TextSubTitle = styled.p`
  color: var(--hover);
  font-size: 26px;
  font-family: 'lt_colored_pencilregular';
  text-align: center;
  letter-spacing: 1;
  font-style: italic;
  line-height: 1.6;
  @media (max-width: 376px) {
    font-size: 24px;
  }
  @media (max-width: 300px) {
    font-size: 20px;
  }
 `;

export const TextPreTitle = styled.p`
color: var(--hover);
font-size: 24px;
font-family: 'lt_colored_pencilregular';
text-align: center;
letter-spacing: 1;
font-style: italic;
line-height: 1.6;
@media (max-width: 376px) {
  font-size: 20px;
}
@media (max-width: 300px) {
  font-size: 18px;
}
 -webkit-animation: text-focus-in 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: text-focus-in 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
`;

export const TextLink = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-family: 'lt_colored_pencilregular';
  text-align: center;
  line-height: 1.6;
  @media (max-width: 376px) {
    font-size: 20px;
  }
  @media (max-width: 300px) {
    font-size: 18px;
  }
  -webkit-animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 30px;
  font-family: 'lt_colored_pencilregular';
  text-align: center;
  line-height: 1.6;
  @media (max-width: 376px) {
    font-size: 24px;
  }
  @media (max-width: 300px) {
    font-size: 18px;
  }
 `;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
