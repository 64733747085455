import React, { Component } from 'react'
import * as s from "../../styles/globalStyles";
import pengie from '../images/pengie.png'
import background from "../images/pengie-bg.jpg";
import "../style/Pengies.css";


class Pengies extends Component {
    render() {
        return (
          <s.Screen style={{ backgroundImage: `url(${background})`, paddingLeft: 25, paddingRight: 25}} >
            <section className="u-clearfix u-section-1" id="sec-c38d">
              <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-text u-text-default u-text-1">Plumpy Pengie Paradise</h2>
                <div className="u-expanded-width-sm u-expanded-width-xs u-list u-list-1">
                  <div className="u-repeater u-repeater-1">
                    <div className="u-align-center u-container-style u-list-item u-repeater-item u-shape-rectangle">
                      <div className="u-container-layout u-similar-container u-container-layout-1">
                        <h3 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-4-base u-text-2" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000"> 2022</h3>
                        <p className="u-text u-text-default u-text-3">INITIAL SUPPLY ON SOLANA</p>
                        <p className="u-text u-text-4">We will consider taking this collection multi-chain based on our experience with the Curious Cubs Club.</p>
                      </div>
                    </div>
                    <div className="u-align-center u-container-style u-list-item u-repeater-item u-shape-rectangle">
                      <div className="u-container-layout u-similar-container u-container-layout-2">
                        <h3 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-4-base u-text-5" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000">0.2 SOL</h3>
                        <p className="u-text u-text-default u-text-6">ESTIMATED MINT PRICE</p>
                        <p className="u-text u-text-7">Subject to changes depending on community input. However, we also plan to have a 3D avatar associated with this project, so pricing is up for discussion.</p>
                      </div>
                    </div>
                    <div className="u-align-center u-container-style u-list-item u-repeater-item u-shape-rectangle">
                      <div className="u-container-layout u-similar-container u-container-layout-3">
                        <h3 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-4-base u-text-8" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000">Metaverse</h3>
                        <p className="u-text u-text-default u-text-9">COLLABORATION WITH ISLES OF META</p>
                        <p className="u-text u-text-10">Our pengies will have their very own winter wonderland carved out in the metaverse. Holders will get a discount when minting this special island type!</p>
                      </div>
                    </div>
                    <div className="u-align-center u-container-style u-list-item u-repeater-item u-shape-rectangle">
                      <div className="u-container-layout u-similar-container u-container-layout-4">
                        <h3 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-4-base u-text-11" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000">3D Avatar</h3>
                        <p className="u-text u-text-default u-text-12">FOR USE IN THE METAVERSE</p>
                        <p className="u-text u-text-13">We are planning a smaller collection of 3D avatars for P3 holders. The idea is to use your 2D NFTs to create a unique 3D NFT with traits from your collected Pengies.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="u-align-left u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-palette-2-base u-group-1">
                  <div className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-container-layout-5">
                    <h2 className="u-custom-font u-font-raleway u-text u-text-14">Information Coming Soon...</h2>
                    <p className="u-text u-text-15">Plumpy Pengie Paradise, aka Code Name: P3, is still in development! We will release more information as we approach winter. Information listed below is for reference and is subject to changes as needed. <br /><br /> Stay Warm, <br /><br /> enlighten1self 💙</p>
                    <img className="u-icon-1" src={pengie} alt="pengie" />
                  </div>
                </div>
              </div>
            </section>
          </s.Screen>

)
}
}

export default Pengies