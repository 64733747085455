import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import space from "../images/pxArt.png";
import "../style/Pixel.css";
import Particles from "react-particles";
import attributes from "../data/attributes.json"

const StyledConnectButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: var(--button);
  padding: 15px;
  font-family: 'pixellarimedium';
  color: var(--secondary-text);
  width: 150px;
  cursor: pointer;
  letter-spacing: 1.7px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  @media only screen and (max-width: 280px) {
    letter-spacing: 1px;
    width: 120px;
    }
`;

const StyledMintButton = styled.button`
  border-radius: 15px;
  border: none;
  background-color: var(--button);
  padding: 15px;
  font-family: 'pixellarimedium';
  color: var(--secondary-text);
  width: 150px;
  cursor: pointer;
  letter-spacing: 1.7px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  @media only screen and (max-width: 280px) {
    width: 100px;
    }
`;

const StyledRandomButton = styled.button`
  border-radius: 100px;
  padding: 5px;
  width: 45px;
  height: 45px;
  position: absolute;
  margin-left: 160px;
  margin-top: 10px;
  opacity: 0.9;
  cursor: pointer;
  border: 0;
  background-size: 100%; 
  :hover {
    background-color: var(--hover);
    color: var(--hover-text);
    opacity: 0.9;
    transition: 0.7s;
    -webkit-animation: color-change-5x 6s linear infinite alternate both;
	        animation: color-change-5x 6s linear infinite alternate both;
  }
  @media only screen and (max-width: 1024px) {
    opacity: 0.3;
  }
  @media only screen and (max-width: 512px) {
    width: 35px;
    height: 35px;
    margin-left: 90px;
    margin-top: 5px;
    opacity: 0.4;
  }
  @media only screen and (max-width: 360px) {
    width: 25px;
    height: 25px;
    margin-left: 72px;
    margin-top: 5px;
    opacity: 0.5;
  }
  @media only screen and (max-width: 280px) {
    margin-left: 50px;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    opacity: 0.6;
    }
`;

const ResponsiveWrapper = styled.div`
  display: grid;
  grid-template-rows: [row1-start] 1fr [row1-end] auto [last-line];
  grid-template-columns: [first]  1fr [line2] 1fr [end];
  gap: 10px;
  align-items: center;
  justify-items: stretch;
  padding: 50px;
  position: relative;
  margin-top: 150px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 80px;
  max-width: 1200px;
  font-family: 'pixellarimedium';

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-evenly;
    flex-flow: column wrap;
    max-width: 700px;
    transition: width 0.5s;
    transition: height 0.5s;
    gap: 10px;
  }
  @media (max-width: 700px) {
    max-width: 500px;
    padding: 70px;
  }
  @media (max-width: 500px) {
    max-width: 100%;
    padding: 25px;
  }
  @media (max-width: 280px) {
    border-radius: 35px;
    padding: 5px;
  }
    background:-moz-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%); 
    background:-webkit-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
    background:-o-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00115D', endColorstr='#00FFEE', GradientType=1 );
    background:-ms-linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
    background:linear-gradient(180deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);width: 100%;
`;

const StyledImg = styled.div`
  margin-bottom: 445px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  max-width: 420px;
  padding: 5px;
  -webkit-animation: text-focus-in .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: text-focus-in .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  @media (max-width: 512px) {
    max-width: 256px;
    margin-top: 15px;
    margin-bottom: 276px;
  }
  @media (max-width: 360px) {
    margin-top: 5px;
    margin-bottom: 215px;
  }
  @media (max-width: 280px) {
    margin-top: 10px;
    margin-bottom: 160px;
  }
`;

const StyledLink = styled.a`
  color: var(--hover);
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 1.5px;
  text-decoration: none;
  font-family: 'pixellarimedium';
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
   :hover {
    color: var(--secondary-text);
    transition: 0.7s;
  }
`;

import Web3 from 'web3';
  const options = {
    reconnect: {
        auto: true,
        delay: 5000, // ms
        maxAttempts: 5,
        onTimeout: false
    }}
       
import genesisABI from '../data/CCC_abi.json';
import colorABI from '../data/FOC_abi.json'
import pixelABI from '../data/CPC_abi.json';

const Pixel = () => {

    const CCC_address = "0xfA4bB27710156A8A685f85eD76e747C3B75B0791" 
    const FOC_address = "0xaA1c804A207b58d15b1Fe2a779EB4B44Ca683342";
    const CPC_address = "0x90Cd0448C0207D94694244C0e1D813C287644d2e";
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click "Mint" to Continue`)
    const [name, setName] = useState("")
    const [background, setBackground] = useState("blank")
    const [clothing, setClothing] = useState("blank")
    const [color, setColor] = useState("blank")
    const [eyes, setEyes] = useState("blank")
    const [head, setHead] = useState("blank")
    const [mouth, setMouth] = useState("blank")
    const [piercings, setPiercings] = useState("blank")
    const [variant, setVariant] = useState("blank")
    const [extra, setExtra] = useState("blank")

    const [CONFIG, SET_CONFIG] = useState({
      CONTRACT_ADDRESS: "",
      SCAN_LINK: "",
      NETWORK: {
        NAME: "",
        SYMBOL: "",
        ID: 0,
      },
      NFT_NAME: "",
      SYMBOL: "",
      MAX_SUPPLY: 1,
      WEI_COST: 0,
      WL_WEI_COST: 0,
      DISPLAY_COST: 0,
      WHITELIST_COST: 0,
      HOLDER_MINT: false,
      GAS_LIMIT: 0,
      PRIORITY_FEE: 0,
      ADD_GWEI: 0,
      CHAIN_EXPLORER: "",
      MARKETPLACE: "",
      MARKETPLACE_LINK: "",
      INFURA_LINK: "",
      SHOW_BACKGROUND: false
    });

    const [lonk, setLonk] = useState(`/config/images/preview.png`);
    const [confetti, setConfetti] = useState(0);
    const web3 = new Web3(CONFIG.INFURA_LINK, options);
    var genesisContract = new web3.eth.Contract(genesisABI, CCC_address);
    var colorContract = new web3.eth.Contract(colorABI, FOC_address)
    var pixelContract = new web3.eth.Contract(pixelABI, CPC_address)
    let test;
    
    const claimNFTs = async (test) => {
      let cost;    
      let balance = await web3.eth.getBalance(blockchain.account);
      let total = String(balance);
      let gasLimit = CONFIG.GAS_LIMIT;
      let address = await blockchain.account;
      let holder = await genesisContract.methods.balanceOf(address).call().catch(err => {console.log(err)})
      let holder2 = await colorContract.methods.balanceOf(address).call().catch(err => {console.log(err)})
      let freeMints = await pixelContract.methods.hodlrMints(address).call().catch(err => {console.log(err)})
        console.log("Free Mints Claimed: " + freeMints)
      // Price Check
        if (holder >= 50) {
          let mythicCub = await pixelContract.methods.mythicalCub(address).call().catch(err => {console.log(err)})
          let legendCub = await pixelContract.methods.legendaryCub(address).call().catch(err => {console.log(err)})
            if (mythicCub === true && freeMints < 7) { cost = 0 
            } else if (legendCub === true && freeMints < 5) { cost = 0 
            } else { cost = CONFIG.WL_WEI_COST }
        } else if (holder >= 15) {
          let goldCub = await pixelContract.methods.goldCub(address).call().catch(err => {console.log(err)})
          let diamondCub = await pixelContract.methods.diamondCub(address).call().catch(err => {console.log(err)})
            if (diamondCub === true && freeMints < 4) { cost = 0 
            } else if (goldCub === true && freeMints < 3) { cost = 0 
            } else { cost = CONFIG.WL_WEI_COST }
         } else {
          let copperCub = await pixelContract.methods.copperCub(address).call().catch(err => {console.log(err)})
          let silverCub = await pixelContract.methods.silverCub(address).call().catch(err => {console.log(err)})
            if (silverCub === true && freeMints < 2) { cost = 0 
            } else if (copperCub === true && freeMints < 1) { cost = 0 
            } else if (holder >= 1 || holder2 >= 1) { 
              cost = CONFIG.WL_WEI_COST 
            } else {
              cost = CONFIG.WEI_COST
          }  
        } 
      // Done... Finally!
      console.log("Cost:" + cost)
      let feeGWEI = await web3.eth.getGasPrice();
      let maxGWEI = String(+feeGWEI + +CONFIG.ADD_GWEI);
      let totalCostWei = String(cost);
      let totalGasLimit = String(gasLimit);
  
      console.log("Cost: ", totalCostWei);
      console.log("Your Balance: ", total)
      console.log("Max Gwei: ", maxGWEI);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      
      if (total < cost) {
        console.log("Failed, insufficient funds.");
        setFeedback(`ERROR: Insufficent Funds`);
        setClaimingNft(false);
      } else {
        window.alert("Please do not leave page until minting is complete.🦁")
        await blockchain.smartContract.methods
        .mint()
        .send({
          gasLimit: totalGasLimit,
          maxPriorityFeePerGas: maxGWEI,
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          setFeedback(err.message);
          console.log(err.message)
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          dispatch(fetchData(blockchain.account));
          test = true
        })
        .catch(() => {
          console.log("Error Occurred, Try Again!");
          test = false
        });

        return test
     }
    };
  
    const getData = () => {
      if (blockchain.account !== "" && blockchain.smartContract !== null) {
        dispatch(fetchData(blockchain.account));
      }
    };
  
    const getConfig = async () => {
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const config = await configResponse.json();
      SET_CONFIG(config);
    };
  
    useEffect(() => {
      getConfig();
    }, []);
  
    useEffect(() => {
      getData();
    }, [blockchain.account]);

    const checkSupply = async () => {
      let results = await pixelContract.methods.totalSupply().call().catch(err => {console.log(err)})
      return results
    }

    const randomizer = async () => {

      const testSup = await checkSupply();

      let bg = attributes[0].Background[Math.floor(Math.random()*attributes[0].Background.length)]
      let cl = attributes[1].Clothing[Math.floor(Math.random()*attributes[1].Clothing.length)]
      let co = attributes[2].Color[Math.floor(Math.random()*attributes[2].Color.length)]
      let ey = attributes[3].Eyes[Math.floor(Math.random()*attributes[3].Eyes.length)]
      let he = attributes[4].Head[Math.floor(Math.random()*attributes[4].Head.length)]
      let mo = attributes[5].Mouth[Math.floor(Math.random()*attributes[5].Mouth.length)]
      let pi = attributes[6].Piercing[Math.floor(Math.random()*attributes[6].Piercing.length)]
      let va = attributes[7].Variant[Math.floor(Math.random()*attributes[7].Variant.length)]

      setBackground(bg);
      setClothing(cl);
      setColor(co);
      setEyes(ey);
      setHead(he);
      setMouth(mo)
      setPiercings(pi);
      setVariant(va);
      setName(`Curious Pixel #${+testSup + +1}`)

      if (mo === "silk_shot") {
        setExtra(mo)
      } else {
        setExtra('blank')
      }

      if (ey === "green_laser" || ey === "purple_laser") {
        setExtra(ey)
      } else {
        setExtra('blank')
      }
}

    const handleSubmit = async () => {

      if (background === "blank") {
        window.alert("Please select a background before minting.🫶")
        return
      } else if (clothing === "blank") {
        window.alert("Please select clothing before minting.👕")
        return
      } else if (color === "blank") {
        window.alert("Please select color before minting.🌈")
        return
      } else if (eyes === "blank") {
        window.alert("Please select eyes before minting.👀")
        return
      } else if (head === "blank") {
        window.alert("Please select head before minting.🎃")
        return
      } else if (mouth === "blank") {
        window.alert("Please select mouth before minting.👄")
        return
      } else if (piercings === "blank") {
        window.alert("Please select piercings before minting.🪝")
        return
      } else if (variant === "blank") {
        window.alert("Please select variant before minting.🦁")
        return
      }
      

      // Confirms that user has named the cub
      if (name === "") {
        window.alert("Please name your cub to Mint!")
        return
      }
      if (name.length > 20) {
        window.alert("Cubs have a 20-character limit. Please rename your cub to Mint!")
        return
      }
      // Begins process to mint, disables button
      setClaimingNft(true);
      setConfetti(0);
      // Verifies that minter owns a Genesis or Color Cub
      let address = await blockchain.account;
      let holder = await genesisContract.methods.balanceOf(address).call().catch(err => {console.log(err)})
      let holder2 = await colorContract.methods.balanceOf(address).call().catch(err => {console.log(err)})
      let minted = await checkSupply()
      
        if (minted === null) {
          window.alert("Something went wrong, please try again later!")
          console.log("API KEY ERROR: Report to admin.")
            return
        }
      console.log("Genesis Cubs Owned:", holder)
      console.log("Color Cubs Owned:", holder2)
      console.log("Current Supply:", minted)
      
        if (CONFIG.HOLDER_MINT === true && holder <= 0 && holder2 <= 0) {
            window.alert("You do not own a Genesis or Color Cub, please wait to mint during Public Sale!")
            return
        }

      // Initiate traits the will be checked
      let traitCheck = [{
        "flameWings": 0,
        "demonWings": 0,
        "dracula": 0,
        "hellscapeArmor": 0,
        "diamondArmor": 0,
        "goldArmor": 0
      }];
      // Initiate variable for duplicate check
      let duplicate = false;
      let nameCheck = false

      // Get all the cubs currently minted
      let cubId = +minted + +1; 
      const cubs = await fetch("https://api.enlighten.global:8000/api/cubs")
        .then((response) => {
          return response.json();
        })

      //Only needed for the first mint
      if (cubs.success === false) {
        console.log("Unable to connect to server.")
        return 
      } else {

        // Checks to see if any traits match and returns error if so
        cubs.data.forEach(function(traits) {
          if (
            traits.variant === variant && 
            traits.clothing === clothing &&
            traits.background === background &&
            traits.color === color &&
            traits.head === head &&
            traits.mouth === mouth &&  
            traits.eyes === eyes &&
            traits.piercings === piercings) {
              window.alert("Duplicate Cub has been detected!")
              duplicate = true
              return
            }

            if (traits.name === name) {
              window.alert("This name has been taken!")
              nameCheck = true
              return
            }
          })
        // Checks rare traits are not exceeded
          cubs.data.forEach(function(check) {
            if (check.clothing === "flame_wings") {
              traitCheck[0].flameWings += 1
            }
            if (check.clothing === "demon_wings") {
              traitCheck[0].demonWings += 1
            }
            if (check.clothing === "dracula") {
              traitCheck[0].dracula += 1
            }
            if (check.clothing === "hellscape_armor") {
              traitCheck[0].hellscapeArmor += 1
            }
            if (check.clothing === "diamond_armor") {
              traitCheck[0].diamondArmor += 1
            }
            if (check.clothing === "gold_armor") {
              traitCheck[0].goldArmor += 1
            }
          })
      }

     if (duplicate === true) { 
      setClaimingNft(false);
      setFeedback("Please change traits and try again!")
      return
     }

     if (nameCheck === true) { 
      setClaimingNft(false);
      setFeedback("Please change name and try again!")
      return
     }

     if (traitCheck[0].flameWings >= 7 && clothing === "flame_wings") {
      window.alert("You can no longer mint Angel Wings!")
      setClaimingNft(false);
      setFeedback("Trait limit Exceeded: Angel Wings")
      return
     } 

     if (traitCheck[0].demonWings >= 7 && clothing === "demon_wings") {
      window.alert("You can no longer mint Devil Wings!")
      setClaimingNft(false);
      setFeedback("Trait limit Exceeded: Devil Wings")
      return
     } 

     if (traitCheck[0].dracula >= 15 && clothing === "dracula") {
      window.alert("You can no longer mint Dracula!")
      setClaimingNft(false);
      setFeedback("Trait limit Exceeded: Dracula")
      return
     } 

     if (traitCheck[0].hellscapeArmor >= 20 && clothing === "hellscape_armor") {
      window.alert("You can no longer mint Hellscape Armor!")
      setClaimingNft(false);
      setFeedback("Trait limit Exceeded: Hellscape Armor")
      return
     } 

     if (traitCheck[0].diamondArmor >= 40 && clothing === "diamond_armor") {
      window.alert("You can no longer mint Diamond Armor!")
      setClaimingNft(false);
      setFeedback("Trait limit Exceeded: Diamond Armor")
      return
     } 

     if (traitCheck[0].goldArmor >= 77 && clothing === "gold_armor") {
      window.alert("You can no longer mint Gold Armor!")
      setClaimingNft(false);
      setFeedback("Trait limit Exceeded: Gold Armor")
      return
     } 
      //Checks that Tiered Cubs can only mint the

     if (holder < 7 && color === "silver" || holder < 15 && color === "gold" || holder < 30 && color === "diamond" || holder < 50 && color === "mythical") {
      window.alert("You do not own enough Genesis Cubs to mint this color!")
      setClaimingNft(false);
      setFeedback("Trait exclusive to Genesis Tier")
      return
     }

      const result = await claimNFTs()

      if (result === true) {

       //set image base URI
       let uri = `https://api.enlighten.global:8000/images/${cubId}.png`
    
       //creates the Cub to upload
       let cubSubmit = JSON.stringify({
        cubId: cubId,
        addressId: blockchain.account,
        background: background,
        clothing: clothing,
        color: color,
        eyes: eyes,
        head: head,
        mouth: mouth,
        piercings: piercings,
        variant: variant,
        name: name,
        uri: uri
      })
 
       try {
         let res = await fetch("https://api.enlighten.global:8000/api/cub", {
           method: "POST",
           headers: {
             "Content-Type": "application/json"
           },
           body: cubSubmit
         });
         await res.json();
         if (res.status === 201) {
           setFeedback(`${name} has been created! You have successfully minted your NFT.`);
           setName("");
           setBackground("blank");
           setClothing("blank");
           setColor("blank");
           setEyes("blank");
           setHead("blank");
           setMouth("blank")
           setPiercings("blank");
           setVariant("blank");
         } else {
           setFeedback(`Creation error occured. Report status ${res.status}`);
           return
         }
       } catch (err) {
         console.log(err);
       }
       setLonk(uri)
       setConfetti(1)
       console.log("Pixel Cub has been created!")
       setClaimingNft(false);
      } 

      return test
    };

        return (
          
          <s.Screen style={{  
            backgroundColor: "#1f0e1c", 
            backgroundImage: `url(${space})`, 
            backgroundSize: "contain", 
            paddingLeft: 20, 
            paddingRight: 25, 
            backgroundPositionX: 300, 
            backgroundPositionY: 25
          }}>
            <Particles 
            id="tsparticles"
            options={{
              "fullScreen": {
                "zIndex": 1
              },
              "particles": {
                "number": {
                  "value": 0
                },
                "color": {
                  "value": [
                    "#00FFFC",
                    "#FC00FF",
                    "#fffc00"
                  ]
                },
                "shape": {
                  "type": [
                    "circle",
                    "triangle",
                    "star"
                  ],
                },
                "opacity": {
                  "value": confetti,
                  "animation": {
                    "enable": true,
                    "minimumValue": 0,
                    "speed": 5,
                    "startValue": "max",
                    "destroy": "min"
                  }
                },
                "size": {
                  "value": 3,
                  "random": {
                    "enable": true,
                    "minimumValue": 1
                  }
                },
                "links": {
                  "enable": false
                },
                "life": {
                  "duration": {
                    "sync": true,
                    "value": 5
                  },
                  "count": 1
                },
                "move": {
                  "enable": true,
                  "gravity": {
                    "enable": true,
                    "acceleration": 7
                  },
                  "speed": {
                    "min": 15,
                    "max": 35
                  },
                  "decay": 0.1,
                  "direction": "none",
                  "straight": false,
                  "outModes": {
                    "default": "destroy",
                    "top": "none"
                  }
                },
                "rotate": {
                  "value": {
                    "min": 0,
                    "max": 360
                  },
                  "direction": "random",
                  "move": true,
                  "animation": {
                    "enable": true,
                    "speed": 60
                  }
                },
                "tilt": {
                  "direction": "random",
                  "enable": true,
                  "move": true,
                  "value": {
                    "min": 0,
                    "max": 360
                  },
                  "animation": {
                    "enable": true,
                    "speed": 40
                  }
                },
                "roll": {
                  "darken": {
                    "enable": true,
                    "value": 25
                  },
                  "enable": true,
                  "speed": {
                    "min": 15,
                    "max": 25
                  }
                },
                "wobble": {
                  "distance": 30,
                  "enable": true,
                  "move": true,
                  "speed": {
                    "min": -15,
                    "max": 15
                  }
                }
              },
              "emitters": {
                "life": {
                  "count": 0,
                  "duration": 0.1,
                  "delay": 0.07
                },
                "rate": {
                  "delay": 0.1,
                  "quantity": 150
                },
                "size": {
                  "width": 0,
                  "height": 0
                }
              }
              }} />
          <ResponsiveWrapper>
          <s.Container flex={1} ai={"center"} jc={"center"} style={{ maxWidth: 540 }}>
            <StyledImg jc={"center"} style={{ left: -420 }}>
              <img className="layers" alt={"image"} src={lonk} />
              <img className="layers" alt={"background"} src={require(`../images/Layers/Background/${background}.png`)} />
              <img className="layers" alt={"color"} src={require(`../images/Layers/Color/${color}.png`)} />
              <img className="layers" alt={"variant"} src={require(`../images/Layers/Variant/${variant}.png`)} />
              <img className="layers" alt={"clothing"} src={require(`../images/Layers/Clothing/${clothing}.png`)} />
              <img className="layers" alt={"piercings"} src={require(`../images/Layers/Piercing/${piercings}.png`)} />
              <img className="layers" alt={"mouth"} src={require(`../images/Layers/Mouth/${mouth}.png`)} />
              <img className="layers" alt={"head"} src={require(`../images/Layers/Head/${head}.png`)} />
              <img className="layers" alt={"eyes"} src={require(`../images/Layers/Eyes/${eyes}.png`)} />
              <img className="layers" alt={"extra"} src={require(`../images/Layers/Extras/${extra}.png`)} />
              <StyledRandomButton 
              className="randomizer" 
              type="submit" 
              value="Sumbit" 
              onClick={async () => {
                randomizer();
            }} >             
              <img className="dice" src={require(`../images/dice.png`)} alt="dice" />
            </StyledRandomButton>
            </StyledImg>
            <s.SpacerLine style={{
              background: "#FFFFFF",
              borderRadius: 0, 
              padding: 2,
              marginBottom: 5
              }}/>   
             <s.TextTitle style={{ 
                background: "linear-gradient(to top, #00EEFF 0%, #FFFFFF 50%)", 
                WebkitBackgroundClip: "text", 
                WebkitTextFillColor: "transparent", 
                fontFamily: 'pixellarimedium',
              }}>
              Make-Your-Own
            </s.TextTitle>
            <s.TextTitle style={{ 
                background: "linear-gradient(to top, #00EEFF 0%, #FFFFFF 50%)", 
                WebkitBackgroundClip: "text", 
                WebkitTextFillColor: "transparent", 
                fontFamily: 'pixellarimedium',
              }}>
              Curious Pixel Cubs
            </s.TextTitle>
            <s.SpacerLine style={{
              background: "#FFFFFF",
              borderRadius: 0, 
              padding: 2
              }}/>
            <s.SpacerSmall />
            <s.TextDescription style={{ fontWeight: "400", color: "#ffffff", fontFamily: 'pixellarimedium' }}>
                    Free Mints for Hodlrs
                    </s.TextDescription>
                    <s.TextDescription style={{ fontWeight: "400", color: "#ffffff", fontFamily: 'pixellarimedium' }}>
                      {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                    </s.TextDescription>
          </s.Container>
          <s.Container style={{ fontFamily: 'pixellarimedium'}}>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextSubTitle style={{paddingTop: 25}}>
                  The sale has ended but you can buy {CONFIG.NFT_NAME} on {" "}
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}> 
                  Opensea
                  </StyledLink>
                </s.TextSubTitle>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"} >
                    <s.TextPreTitle style={{ fontFamily: 'pixellarimedium'}}>
                      Click "Connect" to Start
                    </s.TextPreTitle>
                    <s.SpacerSmall />
                    <StyledConnectButton
                      style={{ 
                        fontSize: 20,
                        fontFamily: 'pixellarimedium'
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledConnectButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerLarge />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bolder",
                            fontFamily: 'pixellarimedium',
                            color: "red",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>

                    <div className="Form">

                    <form onSubmit={handleSubmit} style={{color: "white"}}>

                    Background:
                    <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={background} onChange={(e) => setBackground(e.target.value)}>
                          <option select={"blank"} disabled value="blank"> Select Background</option>
                          <option value="blue_pixel">Blue Pixel</option>
                          <option value="green_pixel">Green Pixel</option>
                          <option value="orange_pixel">Orange Pixel</option>
                          <option value="purple_pixel">Purple Pixel</option>
                          <option value="red_pixel">Red Pixel</option>
                          <option value="teal_pixel">Teal Pixel</option>
                          <option value="yellow_pixel">Yellow Pixel</option>
                          <option value="blue_sands">Blue Sands</option>
                          <option value="green_sands">Green Sands</option>
                          <option value="orange_sands">Orange Sands</option>
                          <option value="purple_sands">Purple Sands</option>
                          <option value="red_sands">Red Sands</option>
                          <option value="teal_sands">Teal Sands</option>
                          <option value="yellow_sands">Yellow Sands</option>
                          <option value="blue_space">Blue Space</option>
                          <option value="green_space">Green Space</option>
                          <option value="orange_space">Orange Space</option>
                          <option value="purple_space">Purple Space</option>
                          <option value="red_space">Red Space</option>
                          <option value="teal_space">Teal Space</option>
                          <option value="yellow_space">Yellow Space</option>
                          <option value="blue_splatter">Blue Splatter</option>
                          <option value="green_splatter">Green Splatter</option>
                          <option value="orange_splatter">Orange Splatter</option>
                          <option value="purple_splatter">Purple Splatter</option>
                          <option value="red_splatter">Red Splatter</option>
                          <option value="teal_splatter">Teal Splatter</option>
                          <option value="yellow_splatter">Yellow Splatter</option>
                          <option value="solid_blue">Solid Blue</option>
                          <option value="solid_green">Solid Green</option>
                          <option value="solid_orange">Solid Orange</option>
                          <option value="solid_purple">Solid Purple</option>
                          <option value="solid_red">Solid Red</option>
                          <option value="solid_teal">Solid Teal</option>
                          <option value="solid_yellow">Solid Yellow</option>
                          <option value="daytime_scene">Daytime Scene</option>
                          <option value="nighttime_scene">Nighttime Scene</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                      Clothing:
                      <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={clothing} onChange={(e) => setClothing(e.target.value)}>
                          <option select={"blank"} disabled value="blank"> Select Clothing</option>
                          <option select={"blank"} disabled value="blank">Angel Wings - OUT OF STOCK</option>
                          <option value="blouse">Blouse</option>
                          <option value="blue_hoodie">Blue Hoodie</option>
                          <option value="blue_puffer">Blue Puffer</option>
                          <option value="bomber_jacket">Bomber Jacket</option>
                          <option value="cardigan">Cardigan</option>
                          <option value="clown">Clown</option>
                          <option value="demon_moon_pullover">Demon Moon Pullover</option>
                          <option value="demon_suit">Demon Suit</option>
                          <option value="demon_wings">Demon Wings</option>
                          <option select={"blank"} disabled value="blank">Devil Wings - OUT OF STOCK</option>
                          <option value="diamond_armor">Diamond Armor</option>
                          <option value="diamond_demon_slayer">Diamond Demon Slayer</option>
                          <option value="dracula">Dracula</option>
                          <option select={"blank"} disabled value="blank">Flame Wings - OUT OF STOCK</option>
                          <option value="gold_armor">Gold Armor</option>
                          <option value="golden_demon_slayer">Golden Demon Slayer</option>
                          <option value="gray_hoodie">Gray Hoodie</option>
                          <option value="green_hoodie">Green Hoodie</option>
                          <option value="green_puffer">Green Puffer</option>
                          <option value="hellscape_armor">Hellscape Armor</option>
                          <option value="iron_armor">Iron Armor</option>
                          <option value="jersey">Jersey</option>
                          <option value="leather_jacket">Leather Jacket</option>
                          <option value="moon_pullover">Moon Pullover</option>
                          <option value="mummy">Mummy</option>
                          <option value="none">None</option>
                          <option value="pantsuit">Pantsuit</option>
                          <option value="paw_pullover">Paw Pullover</option>
                          <option value="purple_hoodie">Purple Hoodie</option>
                          <option value="reaper">Reaper</option>
                          <option value="red_jacket">Red Jacket</option>
                          <option value="red_hoodie">Red Hoodie</option>
                          <option value="straight_jacket">Straight Jacket</option>
                          <option value="suit">Suit</option>
                          <option value="tee_shirt">Tee Shirt</option>
                          <option value="vacation_shirt">Vacation Shirt</option>
                          <option value="yellow_puffer">Yellow Puffer</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                      Color:
                      <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={color} onChange={(e) => setColor(e.target.value)}>
                          <option select={"blank"} disabled value="blank"> Select Color</option>
                          <option value="alien">Alien</option>
                          <option value="black">Black</option>
                          <option value="blue">Blue</option>
                          <option value="diamond">Diamond</option>
                          <option value="gold">Gold</option>
                          <option value="green">Green</option>
                          <option value="lobo">Lobo</option>
                          <option value="mythical">Mythical</option>
                          <option value="orange">Orange</option>
                          <option value="phantom">Phantom</option>
                          <option value="pink">Pink</option>
                          <option value="purple">Purple</option>
                          <option value="red">Red</option>
                          <option value="skeleton">Skeleton</option>
                          <option value="silver">Silver</option>
                          <option value="yellow">Yellow</option>
                          <option value="zombie">Zombie</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                      Eyes:
                      <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={eyes} onChange={(e) => {
                          setExtra('blank'); 
                          setEyes(e.target.value);
                        }}>
                          <option select={"blank"} disabled value="blank"> Select Eyes</option>
                          <option value="alien">Alien</option>
                          <option value="angry">Angry</option>
                          <option value="blue">Blue</option>
                          <option value="broken_heart">Broken Heart</option>
                          <option value="brown">Brown</option>
                          <option value="cat">Cat</option>
                          <option value="cyborg">Cyborg</option>
                          <option value="dazed">Dazed</option>
                          <option value="demon">Demon</option>
                          <option value="embarrassed">Embarrassed</option>
                          <option value="fire">Fire</option>
                          <option value="green">Green</option>
                          <option value="green_laser">Green Laser</option>
                          <option value="heart">Heart</option>
                          <option value="orange">Orange</option>
                          <option value="pirate">Pirate</option>
                          <option value="polygon_glasses">Polygon Glasses</option>
                          <option value="purple_laser">Purple Laser</option>
                          <option value="purple">Purple</option>
                          <option value="reptile">Reptile</option>
                          <option value="snarl">Snarl</option>
                          <option value="steampunk">Steampunk</option>
                          <option value="sunglasses">Sunglasses</option>
                          <option value="uwu">UwU</option>
                          <option value="yellow">Yellow</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                      Head:
                      <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={head} onChange={(e) => setHead(e.target.value)}>
                          <option select={"blank"} disabled value="blank"> Select Head</option>
                          <option value="alien_antennae">Alien Antennae</option>
                          <option value="beret">Beret</option>
                          <option value="black_bow">Black Bow</option>
                          <option value="blue_halo">Blue Halo</option>
                          <option value="bow">Bow</option>
                          <option value="candle">Candle</option>
                          <option value="caterpillar">Caterpillar</option>
                          <option value="chef">Chef</option>
                          <option value="crown">Crown</option>
                          <option value="demon_halo">Demon Halo</option>
                          <option value="devil_horns">Devil Horns</option>
                          <option value="dreadlocks">Dreadlocks</option>
                          <option value="egg">Egg</option>
                          <option value="emo">Emo</option>
                          <option value="enlightenft_trucker">enlighteNFT Trucker</option>
                          <option value="fire">Fire</option>
                          <option value="fireball">Fireball</option>
                          <option value="flamehawk">Flamehawk</option>
                          <option value="halo">Halo</option>
                          <option value="iron_helmet">Iron Helmet</option>
                          <option value="jester_hat">Jester Hat</option>
                          <option value="killer_wasps">Killer Wasps</option>
                          <option value="mohawk">Mohawk</option>
                          <option value="mullet">Mullet</option>
                          <option value="none">None</option>
                          <option value="pirate_hat">Pirate Hat</option>
                          <option value="polygon_trucker">Polygon Trucker</option>
                          <option value="propeller_hat">Propeller Hat</option>
                          <option value="red_horns">Red Horns</option>
                          <option value="rose">Rose</option>
                          <option value="rotten_egg">Rotten Egg</option>
                          <option value="super_spikes">Super Spikes</option>
                          <option value="unicorn_horn">Unicorn Horn</option>
                          <option value="wizard_scar">Wizard Scar</option>
                          <option value="zombie_unicorn">Zombie Unicorn</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                      Mouth:
                      <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={mouth} onChange={(e) => setMouth(e.target.value)}>
                          <option select={"blank"} disabled value="blank"> Select Mouth</option>
                          <option value="alien">Alien</option>
                          <option value="bubblegum">Bubblegum</option>
                          <option value="doobie">Doobie</option>
                          <option value="face_mask">Face Mask</option>
                          <option value="fish">Fish</option>
                          <option value="grass">Grass</option>
                          <option value="heartbeat">Heartbeat</option>
                          <option value="kiss">Kiss</option>
                          <option value="ninja">Ninja</option>
                          <option value="normal">Normal</option>
                          <option value="robot">Robot</option>
                          <option value="sharp_teeth">Sharp Teeth</option>
                          <option value="silk_shot">Silk Shot</option>
                          <option value="smiley">Smiley</option>
                          <option value="smirk">Smirk</option>
                          <option value="surprised">Surprised</option>
                          <option value="tongue">Tongue</option>
                          <option value="uwu">UwU</option>
                          <option value="vampire">Vampire</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                      Piercings:
                      <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={piercings} onChange={(e) => setPiercings(e.target.value)}>
                          <option select={"blank"} disabled value="blank"> Select Piercings</option>
                          <option value="all">All</option>
                          <option value="earrings">Earrings</option>
                          <option value="none">None</option>
                          <option value="septum">Septum</option>
                          <option value="snake_bites">Snake Bites</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                      Variant:
                      <s.SpacerXSmall />
                      <label>
                        <select name="traits" id="traits" value={variant} onChange={(e) => setVariant(e.target.value)}>
                          <option select={"blank"} disabled value="blank"> Select Variant</option>
                          <option value="cheetah">Cheetah</option>
                          <option value="lion">Lion</option>
                          <option value="panther">Panther</option>
                          <option value="tiger">Tiger</option>
                        </select>
                      </label>
                      <s.SpacerXSmall />
                       Name Your Cub: 
                       <s.SpacerXSmall />
                      <input
                        type="text"
                        value={name}
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    <s.SpacerSmall />
                    <s.TextSubTitle style={{ fontFamily: 'pixellarimedium'}}>
                      {feedback}
                    </s.TextSubTitle>
                    <s.SpacerSmall />
                    <s.Container>
                      <StyledMintButton  
                        type="submit"
                        style={{ fontSize: 24 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={async (e) => {
                          e.preventDefault();
                          getData();
                          handleSubmit()
                          .catch(() => {
                            console.log("Error, please try again!")
                            window.alert("Server may be disconnected, please report to team!")
                            setClaimingNft(false);     
                          });
                        }}
                      >
                        {claimingNft ? "BUSY" : "MINT"}
                      </StyledMintButton>
                    </s.Container>
                    </form>
                    </div>
                  </>
                )}
              </>
            )}
          </s.Container>
          <s.Container style={{  gridColumn: "span 2" }}>
          <s.SpacerLarge/>
             <s.TextLink >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                View Contract on {CONFIG.CHAIN_EXPLORER}
              </StyledLink>
            </s.TextLink>
            </s.Container>
          </ResponsiveWrapper>
          </s.Screen>
  );               
}

export default Pixel